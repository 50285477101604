import React from 'react';
import './WebServices.css';
import '../../../GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const WebServices = () => {
  return (
    <section className="services-section">
      <div className="container">
        {/* Header */}
        <h1 className="services-title">Our Services</h1>
        <p className="services-descriptionM">
        Our team of highly skilled and experienced mobile developers is committed to turning your ideas into feature-rich, user-friendly mobile applications. Whether you’re a startup, SME, or enterprise, we possess the expertise to provide high-performance mobile solutions across a wide range of industries
        </p>

        {/* Service Cards */}
        <div className="services-grid">
          {/* Front-end Technology */}
          <div className="service-card">
            <div className="service-icon">🖥️</div>
            <h2 className="service-title">iOS App Development</h2>
            <p className="service-descriptionM">
            Our team of expert developers leverages the unique features of Apple's ecosystem to deliver a delightful and high-performance app for your users. By adhering to the highest design standards and utilizing advanced technologies, we ensure your app stands out in the competitive App Store landscape, making a lasting impression on your audience.</p>
          </div>

          {/* Back-end Technology */}
          <div className="service-card">
            <div className="service-icon">💻</div>
            <h2 className="service-title">Android App Development </h2>
            <p className="service-descriptionM">
            We recognize the intricacies of the Android platform and optimize your app to provide smooth functionality and seamless user experiences. Our Android applications are designed to adapt flawlessly to a range of devices, from smartphones to tablets, enhancing user engagement and satisfaction across diverse screen sizes and specifications.
            </p>
          </div>

          {/* Cloud Support */}
          <div className="service-card">
            <div className="service-icon">☁️</div>
            <h2 className="service-title">React Native App Development</h2>
            <p className="service-descriptionM">
            We are dedicated to realizing your product vision with our expertise in React Native app development. We create dynamic and responsive mobile applications that seamlessly operate across platforms, providing a consistent user experience on both iOS and Android devices. This approach not only accelerates development and reduces costs but also enables you to reach your target audience more quickly and achieve a higher return on investment.</p>
          </div>

          {/* Responsive Web Development */}
          <div className="service-card">
            <div className="service-icon">📱</div>
            <h2 className="service-title">Flutter App Development</h2>
            <p className="service-descriptionM">
            Our Flutter app development services enable you to utilize a single codebase that works effortlessly on both iOS and Android devices. By harnessing Flutter's expressive UI and reactive design, we create visually stunning, feature-rich applications that ensure consistent performance and user experience across platforms. This approach saves you time and resources while maximizing your reach and impact.</p>
          </div>

          {/* Cross-Browser Compatibility */}
          <div className="service-card">
            <div className="service-icon">🌐</div>
            <h2 className="service-title">Cross-Browser Compatibility</h2>
            <p className="service-descriptionM">
              Our developers ensure that your website performs flawlessly across various browsers, ensuring a reliable user experience.
            </p>
          </div>

          {/* Performance Optimization */}
          <div className="service-card">
            <div className="service-icon">📊</div>
            <h2 className="service-title">Discovery and Planning</h2>
            <p className="service-descriptionM">
            We begin by collaborating closely with you to fully understand your business goals, target audience, and project requirements. Armed with these insights, we carefully develop a strategic roadmap to turn your vision into a successful mobile app, laying the foundation for its development and success.</p>
          </div>
          {/* Performance Optimization */}
          <div className="service-card">
            <div className="service-icon">📊</div>
            <h2 className="service-title">Design and Development</h2>
            <p className="service-descriptionM">
            Building on the discovery and planning phase, we develop wireframes and prototypes that bring your mobile app vision to life. Our design team integrates your brand identity with user experience principles to create a visually captivating interface. Our skilled developers then implement clean coding practices, responsive frameworks, and strong backend systems to deliver a high-performing mobile application that surpasses your expectations.</p>
          </div>
          {/* Performance Optimization */}
          <div className="service-card">
            <div className="service-icon">📊</div>
            <h2 className="service-title">Testing and Optimization</h2>
            <p className="service-descriptionM">
            Our expert team conducts thorough testing to identify and address any potential issues early on. Through careful optimization, we ensure your app delivers peak performance, robust security, and exceptional reliability. This process guarantees a seamless user experience, setting your product up for long-term success.</p>
          </div>
          {/* Performance Optimization */}
          <div className="service-card">
            <div className="service-icon">📊</div>
            <h2 className="service-title">Deployment and Support</h2>
            <p className="service-descriptionM">
            We support you throughout the deployment phase, ensuring a smooth and successful launch of your mobile application. Post-launch, our dedicated team provides ongoing support and maintenance, ensuring your product remains up-to-date, adapts to evolving user needs, and keeps pace with industry demands.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WebServices;
