import React, { useState, useEffect } from 'react';
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import Services from "../../Component/Services/Services";
import Technologies from "../../Component/Technologies/Technologies";
import '../../Component/GlobalFonts/GlobalFonts.css';
import "./Home.css";
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  const [displayText, setDisplayText] = useState("");
  const words = ["heroIndustries", "Healthcare", "BFSI", "E-commerce", "EdTech", "MarTech", "Shipping."];

  const handleNavigation = () => {
    navigate('/contact-us');
  };

  useEffect(() => {
    let currentWordIndex = 0;

    const intervalId = setInterval(() => {
      // Update display text to show only the current word
      setDisplayText(words[currentWordIndex]);

      // Move to the next word, and loop back to the start when all words are displayed
      currentWordIndex = (currentWordIndex + 1) % words.length;
    }, 1000); // Delay between each word (1000ms = 1 second)

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []);

  return (
    <div className="home-container">
      <Header />
      <main className="hero-section-dev">
        <div className="hero-text-dev">
          <h1 className="ai-text">Harnessing GenAI to Revolutionize, {displayText}</h1>
          <p className="ai-text-details">
            Unlock the success of your digital product with Generative AI. As your dedicated partner, we specialize in
            product development, innovative design, and application modernization, delivering comprehensive end-to-end
            solutions fueled by the power of Generative AI.
          </p>
          <button className="cta-button" onClick={handleNavigation}>Book Free GenAI Consultation</button>
        </div>
      </main>
      <Services />
      <Technologies />
      <Footer />
    </div>
  );
};

export default Home;
