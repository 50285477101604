import React from 'react';
import './WebServices.css';
import '../../../GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const WebServices = () => {
  return (
    <section className="services-section">
      <div className="container">
        {/* Header */}
        <h1 className="services-title">Our Services</h1>
        <p className="services-descriptionU">From designing intuitive software interfaces to creating user-focused websites, we provide a wide range of services tailored to meet the varied needs of the UX design industry.</p>

        {/* Service Cards */}
        <div className="services-grid">
          {/* Front-end Technology */}
          <div className="service-card">
            <div className="service-icon">🖥️</div>
            <h2 className="service-title">User Experience Design</h2>
            <p className="service-descriptionU">We understand the value of a great user experience, which is why we prioritize creating user-friendly interfaces for your digital products. By leveraging the latest intuitive design trends and principles, we ensure your products captivate and connect with your audience, promoting positive interactions and building brand loyalty.</p>
          </div>

          {/* Back-end Technology */}
          <div className="service-card">
            <div className="service-icon">💻</div>
            <h2 className="service-title">UX Research and Testing</h2>
            <p className="service-descriptionU">We perform comprehensive UX research and testing to uncover key insights into your users' preferences and behaviors. By understanding your target audience, we customize the design and functionality of your products to effectively meet their needs and expectations.</p>
          </div>

          {/* Cloud Support */}
          <div className="service-card">
            <div className="service-icon">☁️</div>
            <h2 className="service-title">Interactive Prototyping</h2>
            <p className="service-descriptionU">We create interactive prototypes to visualize and test the user journey before development. This iterative approach enables us to collect user feedback and fine-tune the design, ensuring the final product aligns perfectly with your users' expectations.</p>
          </div>

          {/* Responsive Web Development */}
          <div className="service-card">
            <div className="service-icon">📱</div>
            <h2 className="service-title">UX Writing</h2>
            <p className="service-descriptionU">We prioritize clear, human-centric content to ensure effective communication. By crafting messages that deliver precisely what your customers need, we create a seamless user experience that enhances engagement and satisfaction.</p>
          </div>

          {/* Cross-Browser Compatibility */}
          <div className="service-card">
            <div className="service-icon">🌐</div>
            <h2 className="service-title">Interaction Design</h2>
            <p className="service-descriptionU">We know that seamless interactions are key to an outstanding user experience. With a keen attention to detail and a deep understanding of user psychology, we transform your digital platform into an engaging, memorable journey for your customers.</p>
          </div>

        </div>
      </div>
    </section>
  );
};

export default WebServices;
