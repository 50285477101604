import Header from "../../Component/Header/Header";  // Import the Header component
import Footer from "../../Component/Footer/Footer";  // Import the Footer component
import Mission from "../../Component/MissionComponent/Mission";  // Import the Mission component
import './ContactUs.css';
import '../../Component/GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css
import ContactForm from "../../Component/ContactForm/ContactForm";  // Import the ContactForm component

function ContactUs() {
  return (
    <div className="home-container">
      <Header />

      <div className="hero-sectionC">
        <div className="hero-textA">
          <h1 className="hero-aboutA">Let's Discuss what we can Build, and Scale Together</h1>
          <p className="about-disA">
          We would love to hear from you and will be in touch shortly.</p>
        </div>
      </div>
      <div style={{marginTop:'20px',marginBottom:'20px'}}>
        <ContactForm/>
      </div>
      <Footer />

    </div>
  );
}

export default ContactUs;
