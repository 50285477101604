import React, { useState, useEffect, useRef } from 'react';
import './Header.css'; // Import the CSS file
import logo from '../../assets/images/Fingerss-Logo.png'; // Correct path to the image
import { Link } from "react-router-dom";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const menuRef = useRef(null);
  const submenuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setSubmenuOpen(false); // Close submenu when the main menu is toggled
  };

  const toggleSubmenu = () => {
    setSubmenuOpen(!submenuOpen); // Toggle submenu
  };

  // Close the menu and submenu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
        setSubmenuOpen(false); // Also close submenu if open
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  // Handle menu item click (other than submenu)
  const handleMenuClick = () => {
    if (submenuOpen) {
      setSubmenuOpen(false); // Close the submenu when any menu item is clicked
    }
  };

  return (
    <header className="header">
      <div className="logo">
        <Link className="logoText" to="/">
          <img style={{ width: '110px', height: '30px' }} src={logo} alt="Logo" />
        </Link>
      </div>
      <div className={`menu-icon ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
        &#9776;
      </div>
      <nav className={`nav ${menuOpen ? 'open' : ''}`} ref={menuRef}>
        <ul className="menu">
          <li onClick={toggleSubmenu} className="services-menu" ref={submenuRef}>
            Our Services
            <ul className={`submenu ${submenuOpen ? 'open' : ''}`}>
              <li><Link to="/generative-AI">AI Development</Link></li>
              <li><Link to="/web-development">Website Development</Link></li>
              <li><Link to="/mobile-development">Mobile Application Development</Link></li>
              <li><Link to="/ux-desing">UI/UX Design</Link></li>
              <li><Link to="/seo-digital-marketing">SEO & Digital Marketing</Link></li>
            </ul>
          </li>
          {/* Other menu items */}
          <li onClick={handleMenuClick}><Link to="/careers">Careers</Link></li>
          <li onClick={handleMenuClick}><Link to="/about-us">About Us</Link></li>
          <li onClick={handleMenuClick}><Link to="/contact-us">Contact Us</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
