import React from 'react';
import './WhyChoose.css';
import '../../../GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const WhyChoose = () => {
  return (
    <div className="container">
      <h1 className="title">Why Choose fingerss?</h1>
      <p className="description">
      We combine technical expertise with industry-specific knowledge to deliver exceptional value to our clients. Our key strengths include:</p>

      <div className="info-box">
        <p>
        In the retail sector, we developed a custom chatbot for a prominent e-commerce platform that effectively guided customers through the purchasing process. This initiative led to a 20% increase in conversions and a significant decrease in customer support tickets.</p>
      </div>

      <div className="info-box">
        <p>
        For a travel agency, our chatbot offered personalized recommendations and assistance, understanding customer travel preferences and providing real-time information. This resulted in a 30% increase in bookings and enhanced customer satisfaction.
        </p>
      </div>

      <div className="info-box">
        <p>
        In the banking sector, our chatbot helped customers with account inquiries and transactions. Its accurate responses and intuitive user experience contributed to a 40% reduction in call center inquiries and improved overall customer engagement.</p>
      </div>
    </div>
  );
};

export default WhyChoose;
