import Header from "../../Component/Header/Header";  // Import the Header component
import Footer from "../../Component/Footer/Footer";  // Import the Footer component
import CaseStudies from "../../Component/CaseStudies/CaseStudies";  // Import the CaseStudies component
import ContactForm from "../../Component/ContactForm/ContactForm";  // Import the ContactForm component

import './OurWork.css';
import '../../Component/GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

function OurWork() {
  return (
    <div className="home-container">
      {/* Use the Header component */}
      <Header />
      <div className="hero-section">
        <div className="hero-text">
          <h1 className="Our-Work-text">Our Work</h1>
          <p className="Our-Work-disc">
            We have built top-quality software products and helped to grow businesses.
            Read more about how we developed innovative products in various industries,
            each reflecting our passion for offering exceptional services and crafting the extraordinary.
          </p>
        </div>
      </div>

      {/* Use the Our Work component */}
      <CaseStudies />

      <div className="flex-container">
        <img
          src="https://creativeblenddesign.com/wp-content/uploads/2024/02/cbd-AI-2024-blog.jpg"
          alt="Illustration"
        />
        <div className="contact-form">
          <ContactForm />
        </div>
      </div>

      {/* Use the Footer component */}
      <Footer />
    </div>
  );
}

export default OurWork;
