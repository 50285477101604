// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './screen/home/Home';
import OurServices from './screen/ourServices/OurServices';
import OurWork from './screen/ourWork/OurWork';
import Careers from './screen/careers/Careers';
import AboutUs from './screen/aboutUs/AboutUs';
import ContactUs from './screen/contactUs/ContactUs';
import WebDevelopment from '../src/Component/OurServicesComponent/WebDevelopment/WebDevelopment';
import MobileDevelopment from '../src/Component/OurServicesComponent/MobileDevelopment/MobileDevelopment';
import UXDesing from '../src/Component/OurServicesComponent/UXDesing/UXDesing';
import GenerativeAI from '../src/Component/OurServicesComponent/GenerativeAI/GenerativeAI';
import DevOpsServices from '../src/Component/OurServicesComponent/DevOpsServices/DevOpsServices';
import QualityEngineering from '../src/Component/OurServicesComponent/QualityEngineering/QualityEngineering';
import DataScience from '../src/Component/OurServicesComponent/DataScience/DataScience';
import LowCodePlatform from '../src/Component/OurServicesComponent/LowCodePlatform/LowCodePlatform';
import PrivacyPolicy from '../src/screen/privacyPolicy/PrivacyPolicy';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ourServices" element={<OurServices />} />
        <Route path="/ourWork" element={<OurWork />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/web-development" element={<WebDevelopment />} />
        <Route path="/mobile-development" element={<MobileDevelopment />} />
        <Route path="/ux-desing" element={<UXDesing />} />
        <Route path="/generative-AI" element={<GenerativeAI />} />
        <Route path="/seo-digital-marketing" element={<DevOpsServices />} />
        <Route path="/quality-engineering" element={<QualityEngineering />} />
        <Route path="/data-science" element={<DataScience />} />
        <Route path="/lowCode-platform" element={<LowCodePlatform />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* Add other routes here */}
      </Routes>
    </Router>
  );
}

export default App;
