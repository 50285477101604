import React from 'react';
import './WhyChoose.css';
import '../../../GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const WhyChoose = () => {
  return (
    <section className="how-we-work-section">
      <div className="container">
        {/* Header */}
        <h1 className="how-we-work-title">Why Choose fingerss?</h1>


        {/* Cards Section */}
        <div className="how-we-work-grid">
          {/* Discovery and Planning */}
          <div className="how-we-work-card">
            <h2 className="card-title">🔍 Expertise and Creativity </h2>
            <p className="card-description">Our team consists of experienced UX designers who are dedicated to crafting innovative and creative solutions. We keep abreast of the latest design trends and best practices, ensuring that your UX design remains at the cutting edge of innovation.</p>
          </div>

          {/* Design and Development */}
          <div className="how-we-work-card">
            <h2 className="card-title">✏️ User-Centric Approach </h2>
            <p className="card-description">We place your users and their needs at the forefront of our design process. Our user-centric approach guarantees that we create solutions tailored to your users' preferences, leading to increased engagement and satisfaction.</p>
          </div>

          {/* Testing and Optimization */}
          <div className="how-we-work-card">
            <h2 className="card-title">✔️ Custom Solutions for Every Project </h2>
            <p className="card-description">We recognize that every project is distinct, so we tailor our services to fit your specific needs. Our personalized approach enables us to deliver custom solutions that effectively address your business objectives.</p>
          </div>

          {/* Deployment and Support */}
          <div className="how-we-work-card">
            <h2 className="card-title">📦 Dedicated Support</h2>
            <p className="card-description">We offer comprehensive support and assistance to ensure the smooth implementation and performance of your UX design solutions. Our friendly and knowledgeable support team is always available to address any concerns and provide timely help.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChoose;
