import React from 'react';
import './CaseStudies.css';
import '../../Component/GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const caseStudies = [
  {
    image: 'https://engineering.orbyta.it/wp-content/uploads/2024/04/ai-e-ingegneria-civile.jpg', // replace with actual image url
    title: 'Migration to Thought Machine\'s Value Core',
    description: 'The client’s business was functioning on a semi-digital legacy system...',
    tags: ['Data Science', 'Cloud Migration', 'Digital Transformation', '+2'],
  },
  {
    image: 'https://engineering.orbyta.it/wp-content/uploads/2024/04/ai-e-ingegneria-civile.jpg', // replace with actual image url
    title: 'Data Science-Driven Sales Forecasting Solution',
    description: 'To optimize sales strategy and maximize cargo vessel capacity...',
    tags: ['Data Science', 'Logistics', 'Shipping', '+5'],
  },
  {
    image: 'https://engineering.orbyta.it/wp-content/uploads/2024/04/ai-e-ingegneria-civile.jpg', // replace with actual image url
    title: 'Driving Sales Operations and Revenue Growth',
    description: 'The client develops sales growth tools and helps create a sales...',
    tags: ['Data Science', 'MarTech', 'Machine Learning', '+1'],
  },
  {
    image: 'https://engineering.orbyta.it/wp-content/uploads/2024/04/ai-e-ingegneria-civile.jpg', // replace with actual image url
    title: 'Leveraging Technology to Bridge the Gap...',
    description: 'For our client, the traditional methods of capturing foot data...',
    tags: ['Healthcare', 'Health IT', 'Healthcare Technology'],
  },
  {
    image: 'https://engineering.orbyta.it/wp-content/uploads/2024/04/ai-e-ingegneria-civile.jpg', // replace with actual image url
    title: 'Investing in EHR Workflow Optimization Propels...',
    description: 'Unlike traditional stethoscopes, our client’s innovative...',
    tags: ['Healthcare', 'Health IT', 'Healthcare Technology'],
  },
  {
    image: 'https://engineering.orbyta.it/wp-content/uploads/2024/04/ai-e-ingegneria-civile.jpg', // replace with actual image url
    title: 'Secret Management with Hashicorp Vault',
    description: 'The client recognized the criticality of securing credentials...',
    tags: ['DevOps', 'Cloud Security', 'BFSI', '+6'],
  },
];

const CaseStudies = () => {
  return (
    <div className="our-work-section">
      <h2 className="section-title" style={{color:"#000"}}>Our Work</h2>
      <p className="section-subtitle" style={{color:"#000"}}>
      We are committed to delivering exceptional service and software solutions, as your success is our ultimate achievement.
      </p>

      <div className="case-studies-grid">
        {caseStudies.map((caseStudy, index) => (
          <div className="case-study-card" key={index}>
            <img src={caseStudy.image} alt={caseStudy.title} className="case-study-image" />
            <div className="case-study-content">
              <h3 className="case-study-title">{caseStudy.title}</h3>
              <p className="case-study-description">{caseStudy.description}</p>
              <div className="case-study-tags">
                {caseStudy.tags.map((tag, tagIndex) => (
                  <span className="tag" key={tagIndex}>{tag}</span>
                ))}
              </div>
              <a href="/" className="case-study-link">READ MORE</a>
            </div>
          </div>
        ))}
      </div>

    </div>
  );
};

export default CaseStudies;
