import React from 'react';
import './Mission.css'; // Import the CSS file for styling
import '../../Component/GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const Mission = () => {
  return (
    <div className="mission-section">
      <div className="mission-header">
        <h1 className='our-miss'>Our Mission</h1>
        <p className='our-dis'>
        At Fingerss, our mission is to develop innovative and forward-thinking software solutions that drive significant growth and enhance our clients' business performance. We strive to ensure that our clients feel confident and at ease throughout the entire process, working closely with a dedicated and collaborative team.
        </p>
      </div>

      <div className="mission-cards">
        <div className="mission-card">
          <div className="icon-placeholder"> {/* Placeholder for icon */}</div>
          <h3 style={{color:"#fff"}}  >People First</h3>
          <p className='card-dis'>We encourage the growth of our teammates and reward their achievements.</p>
        </div>

        <div className="mission-card">
          <div className="icon-placeholder"> {/* Placeholder for icon */}</div>
          <h3  style={{color:"#fff"}}>Quality Coding</h3>
          <p className='card-dis'>We strongly believe in the importance of using clean and easy-to-understand code in all the products we build.</p>
        </div>

        <div className="mission-card">
          <div className="icon-placeholder"> {/* Placeholder for icon */}</div>
          <h3  style={{color:"#fff"}}>Ownership</h3>
          <p className='card-dis'>We perform as owners and are individually accountable for what we deliver.</p>
        </div>

        <div className="mission-card">
          <div className="icon-placeholder"> {/* Placeholder for icon */}</div>
          <h3  style={{color:"#fff"}}>User Perspective</h3>
          <p className='card-dis'>We put ourselves in the users' shoes because we design and build products for them.</p>
        </div>
      </div>
    </div>
  );
};

export default Mission;
