import Header from "../../Header/Header";  // Import the Header component
import Footer from "../../Footer/Footer";  // Import the Footer component
import './DataScience.css';
import WebServices from "./webServices/WebServices";  // Import the WebServices screen
import HowWeWork from "./howWeWork/HowWeWork";  // Import the HowWeWork screen
import Expertise from "./expertise/Expertise";  // Import the Expertise screen
import '../../../Component/GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

function DataScience() {
  return (
    <div className="home-container">
      {/* Use the Header component */}
      <Header />
      <div className="hero-section">
        <div className="hero-text">
          <h1 className="hero-title">Revolutionizing Business with Data Science and AI</h1>
          <p className="hero-dis">Let's transform your business with insights, automation, personalization, and real-time decision-making using data science and AI.</p>
          <button className="cta-button">Book Free GenAI Consultation</button>
        </div>
      </div>
      {/* Use the WebServices screen */}
      <WebServices />
      {/* Use the WebServices screen */}
      <HowWeWork />
      {/* Use the WebServices screen */}
      {/* <Expertise /> */}
      {/* Use the Footer component */}
      <Footer />
    </div>
  );
}

export default DataScience;
