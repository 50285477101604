import React from 'react';
import './PrivacyPolicy.css';
import Header from "../../Component/Header/Header";  // Import the Header component
import Footer from "../../Component/Footer/Footer";  // Import the Footer component
import '../../Component/GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const PrivacyPolicy = () => {
    return (
        <div>
            <Header />
            <div className="privacy-container">
                <h1 className="privacy-title">Privacy Policy</h1>
                <p className='policy-discription'>
                    This Privacy Policy describes our policies and procedures on the collection, use, and disclosure of your information when you use our services and informs you about your privacy rights and how the law protects you.
                    We use your personal data to provide and improve our services. By using our services, you agree to the collection and use of information in accordance with this Privacy Policy.
                </p>

                <h2 className='title-text'>1. Interpretation and Definitions</h2>
                <h3 className='interpretation-title'>Interpretation:</h3>
                <p className='policy-discription'>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or plural form.</p>

                <h3>Definitions:</h3>
                <ul className='policy-discription'>
                    <li><strong>Account</strong> means a unique account created for you to access our service or parts of our service.</li>
                    <li><strong>Affiliate</strong> means an entity that controls, is controlled by, or is under common control with a party.</li>
                    <li><strong>Company</strong> (referred to as "Fingerss," "We," "Us," or "Our") refers to Fingerss.</li>
                    <li><strong>Cookies</strong> are small files placed on your device by a website, containing details of your browsing history.</li>
                    <li><strong>Country</strong> refers to Gujarat, India.</li>
                    <li><strong>Device</strong> means any device that can access the service, such as a computer, cellphone, or digital tablet.</li>
                    <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
                    <li>
                        <strong>Service</strong> refers to the website, <a href="https://www.fingerss.com" target="_blank" rel="noopener noreferrer">www.fingerss.com</a>.
                    </li>
                    <li><strong>Service Provider</strong> means any natural or legal person who processes data on behalf of the Company.</li>
                    <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the service or from the service infrastructure itself.</li>
                    <li><strong>You</strong> means the individual accessing or using the service, or the company or other legal entity on behalf of which such individual is accessing or using the service.</li>
                </ul>

                <h2 className='title-text'>2. Collecting and Using Your Personal Data</h2>
                <h3 className='interpretation-title'>Types of Data Collected:</h3>
                <p className='interpretation-title'><strong>Personal Data:</strong></p>
                <ul className='policy-discription'>
                    <li>Email address</li>
                    <li>First name and last name</li>
                    <li>Phone number</li>
                    <li>Usage Data</li>
                </ul>

                <h3 className='interpretation-title'>Usage Data:</h3>
                <p className='policy-discription'>
                    Usage Data is collected automatically when using the service. This may include information such as your device's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our service that you visit, the time and date of your visit, time spent on those pages, unique device identifiers, and other diagnostic data.
                </p>

                <h2 className='interpretation-title'>3. Tracking Technologies and Cookies</h2>
                <p>
                    We use cookies and similar tracking technologies to track the activity on our service and store certain information. The technologies we use may include:
                </p>
                <ul className='policy-discription'>
                    <li><strong>Cookies or Browser Cookies:</strong> A cookie is a small file placed on your device. You can instruct your browser to refuse all cookies.</li>
                    <li><strong>Web Beacons:</strong> Our service and emails may contain small electronic files known as web beacons.</li>
                </ul>

                <h2 className='interpretation-title'>4. Use of Your Personal Data</h2>
                <p className='policy-discription'>The Company may use Personal Data for the following purposes:</p>
                <ul className='policy-discription'>
                    <li>To provide and maintain our service, including monitoring usage.</li>
                    <li>To manage your account.</li>
                    <li>To contact you.</li>
                    <li>To provide news and special offers.</li>
                </ul>

                <h2 className='interpretation-title'>5. Retention of Your Personal Data</h2>
                <p className='policy-discription'>We will retain your Personal Data only for as long as necessary for the purposes set out in this Privacy Policy.</p>

                <h2 className='interpretation-title'>6. Transfer of Your Personal Data</h2>
                <p className='policy-discription'>Your information may be transferred to — and maintained on — computers located outside of your jurisdiction.</p>

                <h2 className='interpretation-title'>7. Disclosure of Your Personal Data</h2>
                <p className='policy-discription'>The Company may disclose your personal data in the following circumstances:</p>
                <ul>
                    <li>Business Transactions</li>
                    <li>Law Enforcement</li>
                    <li>Other Legal Requirements</li>
                </ul>

                <h2 className='interpretation-title'>8. Security of Your Personal Data</h2>
                <p className='policy-discription'>We strive to use commercially acceptable means to protect your Personal Data.</p>

                <h2 className='interpretation-title'>9. Children's Privacy</h2>
                <p className='policy-discription'>We do not knowingly collect personally identifiable information from anyone under 13.</p>

                <h2 className='interpretation-title'>10. Links to Other Websites</h2>
                <p className='policy-discription'>Our service may contain links to third-party websites.</p>

                <h2 className='interpretation-title'>11. Changes to this Privacy Policy</h2>
                <p className='policy-discription'>We may update our Privacy Policy from time to time.</p>

                <h2 className='interpretation-title'>12. Contact Us</h2>
                <p className='policy-discription'>If you have any questions about this Privacy Policy, please contact us at:</p>

            </div>
            {/* Use the Footer component */}
            <Footer />
        </div>

    );
};

export default PrivacyPolicy;
