import React from 'react';
import './HowWeWork.css';
import '../../../GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const HowWeWork = () => {
  return (
    <section className="how-we-work-section">
      <div className="container">
        {/* Header */}
        <h1 className="how-we-work-title">How we Work</h1>
        <p className="how-we-work-description">
        Curious about how we assist our clients? Here’s a look at our streamlined process:
        </p>

        {/* Cards Section */}
        <div className="how-we-work-grid">
          {/* Discovery and Planning */}
          <div className="how-we-work-card">
            <h2 className="card-title" >🔍 Discovery and Planning</h2>
            <p className="card-description">
            We start by thoroughly understanding your business needs and goals. Our expert team analyzes your current infrastructure and recommends the most effective solutions to achieve your objectives. Whether it's SEO, Digital Marketing, or a tailored software solution, we ensure a strategic plan that aligns with your business vision.
            </p>
          </div>

          {/* Design and Development */}
          <div className="how-we-work-card">
            <h2 className="card-title" >✏️ Design and Development</h2>
            <p className="card-description">
            Our talented developers and designers create a detailed blueprint focusing on performance, scalability, and an optimal user experience. By leveraging modern technologies, we speed up the development process without sacrificing quality. We adapt methodologies like Waterfall, Agile (including Scrum), and Kanban to suit the unique needs of your project, ensuring seamless collaboration and successful results.
            </p>
          </div>

          {/* Testing and Optimization */}
          <div className="how-we-work-card">
            <h2 className="card-title" >✔️ Testing and Optimization</h2>
            <p className="card-description">
            Before launching, our QA team rigorously tests the solution through various testing methods, including unit testing, integration testing, and system testing. We are committed to delivering perfection, ensuring that your product meets the highest standards of performance, security, and reliability.
            </p>
          </div>

          {/* Deployment and Support */}
          <div className="how-we-work-card">
            <h2 className="card-title" >📦 Deployment and Support</h2>
            <p className="card-description">
            Once the solution is ready, we deploy it seamlessly into your environment. Our work doesn’t stop there—we provide ongoing support, monitoring, and maintenance to ensure that your application or digital marketing strategy continues to run smoothly and delivers consistent results.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowWeWork;
