import React from 'react';
import './HowWeWork.css';
import '../../../GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const HowWeWork = () => {
  return (
    <section className="how-we-work-section">
      <div className="container">
        {/* Header */}
        <h1 className="how-we-work-title">How we Work</h1>
        <p className="how-we-work-description">
        Curious about how we assist our clients? Here’s a glimpse into our team's process.
        </p>

        {/* Cards Section */}
        <div className="how-we-work-grid">
          {/* Discovery and Planning */}
          <div className="how-we-work-card">
            <h2 className="card-title">🔍 UX Strategy</h2>
            <p className="card-description">We take the time to understand your business goals, target audience, and specific challenges. By gaining deep insights into your needs, we can create tailored UX design solutions that deliver meaningful results.</p>
          </div>

          {/* Design and Development */}
          <div className="how-we-work-card">
            <h2 className="card-title">✏️ Ideation and Design Planning</h2>
            <p className="card-description">Our experienced team engages in brainstorming sessions to generate ideas and formulate a detailed design plan aligned with your project's objectives. We thoughtfully consider user flow, navigation, and visual elements to create a design that enhances user interactions and overall experience.</p>
          </div>

          {/* Testing and Optimization */}
          <div className="how-we-work-card">
            <h2 className="card-title">✔️ UI and UX Design</h2>
            <p className="card-description">Our talented designers turn the plan into reality by creating visually captivating interfaces and seamless user experiences. We prioritize aesthetics, accessibility, and usability to deliver a design that makes a lasting impact on your users</p>
          </div>

          {/* Deployment and Support */}
          <div className="how-we-work-card">
            <h2 className="card-title">📦 Iterative Testing and Refinement</h2>
            <p className="card-description">We perform comprehensive testing to assess the design's effectiveness and gather valuable user feedback. This iterative process helps us identify areas for enhancement and refine the design, ensuring optimal user satisfaction.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowWeWork;
