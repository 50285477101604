import Header from "../../Header/Header";  // Import the Header component
import Footer from "../../Footer/Footer";  // Import the Footer component
import './GenerativeAI.css';
import WebServices from "./webServices/WebServices";  // Import the WebServices screen
import HowWeWork from "./howWeWork/HowWeWork";  // Import the HowWeWork screen
// import Expertise from "./expertise/Expertise";  // Import the Expertise screen
import WhyChoose from "./WhyChoose/WhyChoose";  // Import the Expertise screen
import '../../../Component/GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

function GenerativeAI() {
  return (
    <div className="home-container">
      <Header />

      <div className="hero-sectionG">
        <div className="hero-textA">
          <h1 className="hero-aboutA">Transform customer interactions with tailor-made chatbots driven by Generative AI! </h1>
          <p className="about-disA">
          Boost engagement through customized chatbot solutions.</p>
        </div>
      </div>

      {/* Use the WebServices screen */}
      <WebServices />
      {/* Use the WhyChoose screen */}
      <WhyChoose />
      {/* Use the WebServices screen */}
      <HowWeWork />
      {/* Use the Expertise screen */}
      {/* <Expertise /> */}
      {/* Use the Footer component */}
      <Footer />
    </div>
  );
}

export default GenerativeAI;
