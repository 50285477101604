// src/components/Footer.js
import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css"; // Make sure to create a separate CSS file for Footer
import '../../Component/GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        
        <div className="footer-links">
          <div className="footer-column">
            <h3 className="page-title">Pages</h3>
            <ul>
              <li><Link className="policy-text" to="/privacy-policy">Privacy Policy</Link></li>
            </ul>
          </div>
          <div className="footer-column">
            <h3 className="page-title"> In Media</h3>
            <ul>
            <li><Link className="policy-text" to="/contact-us">Contact Us</Link></li>
            <li><Link className="policy-text" to="/">hello@fingerss.com</Link></li>
            <li><Link className="policy-text" to="/">Phone no.</Link></li>
            <li><a href="https://www.fingerss.com" target="_blank" rel="noopener noreferrer">www.fingerss.com</a>.</li>

            </ul>
          </div>
          <div className="footer-column">
            <h3 className="page-title">Social Media</h3>
            <ul>
              <li><Link className="policy-text" to="/">Twitter</Link></li>
              <li><Link className="policy-text" to="/">Instagram</Link></li>
              <li><a href="https://www.linkedin.com/company/fingerss/?viewAsMember=true" target="_blank" rel="noopener noreferrer" className="policy-text">LinkedIn</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-logo">
          <p className="policy-text">© 2024 fingerss creative studio.</p>
        </div>
    </footer>
  );
};

export default Footer;
