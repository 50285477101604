import Header from "../../Component/Header/Header";  // Import the Header component
import Footer from "../../Component/Footer/Footer";  // Import the Footer component
import './OurServices.css';

function OurServices() {
  return (
    <div className="home-container">
      {/* Use the Header component */}
      <Header />
      <div className="hero-section">
        <div className="hero-text">
          <h1>Our Work</h1>
          <p>
            We have built top-quality software products and helped to grow businesses.
            Read more about how we developed innovative products in various industries,
            each reflecting our passion for offering exceptional services and crafting the extraordinary.
          </p>
          <button className="cta-button">Book Free GenAI Consultation</button>
        </div>
      </div>
      {/* Use the Footer component */}
      <Footer />
    </div>
  );
}

export default OurServices;
