import Header from "../../Header/Header";  // Import the Header component
import Footer from "../../Footer/Footer";  // Import the Footer component
import './LowCodePlatform.css';
import WebServices from "./webServices/WebServices";  // Import the WebServices screen
import HowWeWork from "./howWeWork/HowWeWork";  // Import the HowWeWork screen
import Expertise from "./expertise/Expertise";  // Import the Expertise screen
import '../../../Component/GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

function LowCodePlatform() {
  return (
    <div className="home-container">
      {/* Use the Header component */}
      <Header />
      <div className="hero-section">
        <div className="hero-text">
          <h1>Simplify Application Development with our Low-Code Services!</h1>
          <p>
          Expedite the application development process with our revolutionary low-code platform services.</p>
          {/* <button className="cta-button">Book Free GenAI Consultation</button> */}
        </div>
      </div>
      {/* Use the WebServices screen */}
      <WebServices />
      {/* Use the WebServices screen */}
      <HowWeWork />
      {/* Use the WebServices screen */}
      {/* <Expertise /> */}
      {/* Use the Footer component */}
      <Footer />
    </div>
  );
}

export default LowCodePlatform;
