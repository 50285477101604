import React from 'react';
import './WebServices.css';
import '../../../GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const WebServices = () => {
  return (
    <section className="services-section">
      <div className="container">
        {/* Header */}
        <h1 className="services-title">Our Services</h1>
        <p className="services-description">
          We specialize in crafting bespoke web development solutions. From cutting-edge front-end and back-end solutions to user-centric design, we deliver an unmatched experience.
        </p>

        {/* Service Cards */}
        <div className="services-grid">
          {/* Front-end Technology */}
          <div className="service-card">
            <div className="service-icon">🖥️</div>
            <h2 className="service-title">Search Engine Optimization (SEO):</h2>
            <p className="service-description">
            We offer tailored SEO strategies that enhance your website’s ranking on search engines. Through keyword research, on-page optimization, technical SEO, and link-building, we ensure your business gains organic visibility and attracts the right audience.
            </p>
          </div>

          {/* Back-end Technology */}
          <div className="service-card">
            <div className="service-icon">💻</div>
            <h2 className="service-title">Pay-Per-Click (PPC) Advertising:</h2>
            <p className="service-description">
            Our PPC campaigns are designed to maximize your ROI by targeting specific demographics and delivering immediate traffic to your website. We manage Google Ads, Bing Ads, and social media ad campaigns to ensure cost-effective results.</p>
          </div>

          {/* Cloud Support */}
          <div className="service-card">
            <div className="service-icon">☁️</div>
            <h2 className="service-title">Content Marketing:</h2>
            <p className="service-description">
            Content is at the heart of digital success. Our team crafts compelling content that resonates with your audience, driving engagement and building brand authority. This includes blog writing, video creation, infographics, and more.
            </p>
          </div>

          {/* Responsive Web Development */}
          <div className="service-card">
            <div className="service-icon">📱</div>
            <h2 className="service-title">Social Media Marketing:</h2>
            <p className="service-description">
            We create and manage targeted social media campaigns that engage your audience on platforms like Facebook, Instagram, LinkedIn, and Twitter. Our approach helps you build a loyal customer base and grow your brand.
            </p>
          </div>

          {/* Cross-Browser Compatibility */}
          <div className="service-card">
            <div className="service-icon">🌐</div>
            <h2 className="service-title">Email Marketing:</h2>
            <p className="service-description">
            Our email marketing services help you stay connected with your customers. Through personalized campaigns, we nurture leads, boost customer retention, and drive sales.</p>
          </div>

          {/* Performance Optimization */}
          <div className="service-card">
            <div className="service-icon">📊</div>
            <h2 className="service-title">Conversion Rate Optimization (CRO):</h2>
            <p className="service-description">
            We optimize your website and marketing funnels to convert more visitors into customers. Our CRO strategies ensure your digital channels are geared for maximum profitability.
            </p>
          </div>

          {/* Performance Optimization */}
          <div className="service-card">
            <div className="service-icon">📊</div>
            <h2 className="service-title">Analytics & Reporting:</h2>
            <p className="service-description">
            We provide detailed performance reports and actionable insights to track your campaign success and refine your digital marketing strategy. We use tools like Google Analytics to ensure data-driven decision-making.</p>
          </div>

         
        </div>
      </div>
    </section>
  );
};

export default WebServices;
