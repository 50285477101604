import React from 'react';
import './HowWeWork.css';
import '../../../GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const HowWeWork = () => {
  return (
    <section className="how-we-work-section">
      <div className="container">
        {/* Header */}
        <h1 className="how-we-work-title">How we Work</h1>
        <p className="how-we-work-description">
        Curious about how we assist our clients? Explore our team’s structured approach to delivering exceptional results.
        </p>

        {/* Cards Section */}
        <div className="how-we-work-grid">
          {/* Discovery and Planning */}
          <div className="how-we-work-card">
            <h2 className="card-title">🔍 Discovery and Planning</h2>
            <p className="card-description">
            We kick off the process with collaborative discussions to thoroughly understand your business goals, target audience, and project requirements, allowing us to create a strategic roadmap for success.</p>
          </div>

          {/* Design and Development */}
          <div className="how-we-work-card">
            <h2 className="card-title" >✏️ Design and Development</h2>
            <p className="card-description">
            Building on our discovery and planning phase, our talented designers create wireframes and prototypes to visualize the final website, seamlessly integrating your brand identity and user experience principles. Our expert web developers then bring this design to life, employing clean coding practices, responsive frameworks, and robust back-end systems to ensure a high-performing website.</p>
          </div>

          {/* Testing and Optimization */}
          <div className="how-we-work-card">
            <h2 className="card-title"> ✔️ Testing and Optimization</h2>
            <p className="card-description">
            Our experienced team performs thorough testing to identify and address any potential issues. Through careful optimization, we ensure your product achieves peak performance, enhanced security, and exceptional reliability, elevating it to new heights of success.</p>
          </div>

          {/* Deployment and Support */}
          <div className="how-we-work-card">
            <h2 className="card-title">📦 Deployment and Support</h2>
            <p className="card-description">
            We offer comprehensive support throughout the deployment phase, ensuring a smooth and seamless launch of your web application. Our team is committed to providing ongoing support and maintenance, helping your product thrive and evolve over time.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowWeWork;
