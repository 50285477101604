import React from 'react';
import './WebServices.css';
import '../../../GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const WebServices = () => {
  return (
    <section className="services-section">
      <div className="container">
        {/* Header */}
        <h1 className="services-title">Our Services</h1>
        <p className="services-descriptionW">We excel in creating custom web development solutions. From innovative front-end and back-end development to intuitive, user-focused design, we provide a seamless and unparalleled digital experience.</p>

        {/* Service Cards */}
        <div className="services-grid">
          {/* Front-end Technology */}
          <div className="service-card">
            <div className="service-icon">🖥️</div>
            <h2 className="service-title">Front-end Technology</h2>
            <p className="service-descriptionW">
            We provide cutting-edge front-end development services that transform your product into a user-friendly experience. With our expertise, your product will gain improved usability, seamless navigation, and visually captivating interfaces, delivering an exceptional user experience that stands out.</p>
          </div>

          {/* Back-end Technology */}
          <div className="service-card">
            <div className="service-icon">💻</div>
            <h2 className="service-title">Back-end Technology</h2>
            <p className="service-descriptionW">
            Our skilled team utilizes the latest technologies to develop a robust and scalable back-end infrastructure, optimizing performance, security, and data management. With efficient database solutions and strong API integrations, we ensure a seamless, reliable, and dynamic user experience for your product.</p>
          </div>

          {/* Cloud Support */}
          <div className="service-card">
            <div className="service-icon">☁️</div>
            <h2 className="service-title">Cloud Support</h2>
            <p className="service-descriptionW">
            Our advanced cloud solutions provide the scalability and flexibility your product needs to effortlessly adapt to evolving demands. Unlock limitless growth and success as we optimize your product's infrastructure with our reliable and efficient cloud services.</p>
          </div>

          {/* Responsive Web Development */}
          <div className="service-card">
            <div className="service-icon">📱</div>
            <h2 className="service-title">Responsive Web Development</h2>
            <p className="service-descriptionW">
            Leveraging our expertise in front-end frameworks, we develop responsive websites that effortlessly adapt to any screen size, ensuring a flawless user experience across all devices, from desktops to mobile.</p>
          </div>

          {/* Cross-Browser Compatibility */}
          <div className="service-card">
            <div className="service-icon">🌐</div>
            <h2 className="service-title">Cross-Browser Compatibility</h2>
            <p className="service-descriptionW">
            Our expert developers guarantee that your website functions seamlessly across all major browsers, providing a consistent and dependable user experience for every visitor.</p>
          </div>

          {/* Performance Optimization */}
          <div className="service-card">
            <div className="service-icon">📊</div>
            <h2 className="service-title">Performance Optimization</h2>
            <p className="service-descriptionW">
            We apply industry-leading techniques to optimize your website's performance, ensuring faster load times and smooth navigation, ultimately improving user satisfaction and engagement.</p>
          </div>

          {/* Performance Optimization */}
          <div className="service-card">
            <div className="service-icon">📊</div>
            <h2 className="service-title">MVP Development</h2>
            <p className="service-descriptionW">Accelerate your product launch with our MVP development services. We swiftly transform your product vision into reality, enabling you to validate ideas, collect user feedback, and iterate effectively for optimal results.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WebServices;
