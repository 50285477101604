import React from 'react';
import './WebServices.css';
import '../../../GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const WebServices = () => {
  return (
    <section className="services-section">
      <div className="container">
        {/* Header */}
        <h1 className="services-title">Our Services</h1>
        <p className="services-description">
        We build software solutions with clean code and engage in all stages of product development.
        </p>

        {/* Service Cards */}
        <div className="services-grid">
          {/* Front-end Technology */}
          <div className="service-card">
            <div className="service-icon">🖥️</div>
            <h2 className="service-title">Artificial Intelligence</h2>
            <p className="service-description">
            Embedded analytics in mobile and web service models, actionable business insights, Agile solutions. </p>
          </div>

          {/* Back-end Technology */}
          <div className="service-card">
            <div className="service-icon">💻</div>
            <h2 className="service-title">Data Engineering</h2>
            <p className="service-description">
            360-degree data insight and analytics using data lakes; BI solutions, risk mitigation, and more.</p>
          </div>

          {/* Cloud Support */}
          <div className="service-card">
            <div className="service-icon">☁️</div>
            <h2 className="service-title">Data Science</h2>
            <p className="service-description">
            Data analytics, modelling, and visualisation, development, maintenance of large-scale products.</p>
          </div>

        </div>
      </div>
    </section>
  );
};

export default WebServices;
