import Header from "../../Component/Header/Header";  // Import the Header component
import Footer from "../../Component/Footer/Footer";  // Import the Footer component
import Mission from "../../Component/MissionComponent/Mission";  // Import the Mission component
import OurVision from "./OurVision";  // Import the OurVision component
import './AboutUs.css';
import '../../Component/GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

function AboutUs() {
  return (
    <div className="home-container">
      <Header />

      <div className="hero-sectionA">
        <div className="hero-textA">
          <h1 className="hero-aboutA">At Fingerss, we are a passionate and growing team of innovators and visionaries.</h1>
          <p className="about-disA">
          We believe in every idea our clients bring to us, and with our creative expertise, we transform these ideas into groundbreaking software solutions that stand out in the market.</p>
        </div>
      </div>

      <div className="about-container">
        <h1 className="Who-are">Think IT Solutions, Think Fingerss! Shaping the future of IT innovation!</h1>
        <p className="who-dis">
        Founded with passion, Fingerss has quickly grown into a team of talented professionals dedicated to delivering exceptional results...</p>
      </div>

      <Mission />
      <OurVision />
      <Footer />
    </div>
  );
}

export default AboutUs;
