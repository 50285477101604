import React from 'react';
import './LifeAtCoditas.css'; // Import the CSS file for styling
import '../../Component/GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const LifeAtCoditas = () => {
  return (
    <div className="life-coditas-container">
      <div className="life-coditas-content">
        <h1 className='Life-title'>Life at Fingerss</h1>
        <p className='Life-title-dis'>
        At Fingerss, we understand that our success is built on the growth of our team. That’s why we create a supportive environment where everyone can explore new opportunities for professional and personal development.
        </p>
        <p className='Life-title-dis'>
        We celebrate diversity and individuality, ensuring that monotony is never part of the experience. Life at Fingerss is a vibrant blend of work and enjoyment, where creativity and collaboration are at the forefront. We celebrate milestones together, share laughter, and inspire one another to keep innovating.

        </p>
      </div>
      {/* <div className="life-coditas-image">
        <img src="https://engineering.orbyta.it/wp-content/uploads/2024/04/ai-e-ingegneria-civile.jpg" alt="Life at Coditas" /> 
      </div> */}
        {/* Replace with actual image URL */}
    </div>
  );
};

export default LifeAtCoditas;
