import React from 'react';
import './HowWeWork.css';
import '../../../GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const HowWeWork = () => {
  return (
    <section className="how-we-work-section">
      <div className="container">
        {/* Header */}
        <h1 className="how-we-work-title">How we Work</h1>
        <p className="how-we-work-description">
        Curious about how we support our clients? Here’s a glimpse into our team's process.</p>

        {/* Cards Section */}
        <div className="how-we-work-grid">
          {/* Discovery and Planning */}
          <div className="how-we-work-card">
            <h2 className="card-title">🔍 Discovery and Analysis</h2>
            <p className="card-description">We start by gaining a deep understanding of your industry, target audience, and specific business needs. Our team works closely with you to gather insights, define the project’s scope, and set clear objectives for your custom chatbot solution.</p>
          </div>

          {/* Design and Development */}
          <div className="how-we-work-card">
            <h2 className="card-title"> ✏️ Design and Development</h2>
            <p className="card-description">After thorough analysis, we recommend the ideal AI model and design a chatbot architecture and user flow that aligns with your goals. Our expert developers then use generative AI techniques to build a chatbot that naturally understands and responds to user queries, ensuring meaningful interactions.</p>
          </div>

          {/* Testing and Optimization */}
          <div className="how-we-work-card">
            <h2 className="card-title" >✔️ Testing and Optimization</h2>
            <p className="card-description">We perform comprehensive testing to ensure your chatbot operates seamlessly. By assessing its conversational abilities, accuracy, and responsiveness, we fine-tune its performance. Our goal is to deliver a chatbot that not only meets your expectations but also enhances customer satisfaction.</p>
          </div>

          {/* Deployment and Support */}
          <div className="how-we-work-card">
            <h2 className="card-title">📦 Deployment and Support</h2>
            <p className="card-description">Once the chatbot is prepared, we help you through the deployment process, ensuring it integrates seamlessly with your platforms. We offer comprehensive support and maintenance services to resolve any issues and keep your chatbot functioning optimally.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowWeWork;
