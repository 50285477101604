// Services.js
import React from 'react';
import './Services.css'; // Updated CSS
import '../../Component/GlobalFonts/GlobalFonts.css';  // Import global font styles

const services = [
  {
    icon: '📊', 
    title: 'Artificial Intelligence',
    description: 'Explore endless opportunities with our software solutions powered by Artificial Intelligence.',
    link: '#',
  },
  {
    icon: '💻',
    title: 'Web Development',
    description: 'Overcome business challenges and excel in the digital world with our tailored web development services.',
    link: '#',
  },
  {
    icon: '📱',
    title: 'Mobile Development',
    description: 'Bring your vision to life with our expert native Android and iOS app development services.',
    link: '#',
  },
  {
    icon: '🎨',
    title: 'UX Design',
    description: 'Enhance user engagement and craft delightful experiences with our design solutions, guided by Design Thinking and the Human-Computer Interaction framework.',
    link: '#',
  },
  {
    icon: '🎨',
    title: 'DevOps Services',
    description: 'Gain a competitive edge with our agile DevOps solutions tailored to meet your business needs.',
    link: '#',
  },
  {
    icon: '🎨',
    title: 'Quality Engineering',
    description: 'Elevate your website with our thorough testing services, ensuring top-notch performance and reliability.',
    link: '#',
  },
  {
    icon: '🎨',
    title: 'SEO',
    description: 'Enhance your online presence and drive traffic with our expert SEO services, designed to improve your search engine rankings and visibility.',
    link: '#',
  },
  {
    icon: '🎨',
    title: 'Digital Ads',
    description: 'Maximize your reach and conversion rates with our strategic digital advertising solutions, tailored to target your audience effectively and achieve your marketing goals.',
    link: '#',
  },
];

const Services = () => {
  return (
    <section className="services-section">
      <div className="services-container">
        <h2 className="services-heading">Our Services</h2>
        <p className="services-tagline">We create outstanding software through clean coding practices, agile methodologies, and an emphasis on delivering a remarkable user experience</p>
        <div className="services-card-grid">
          {services.map((service, index) => (
            <div key={index} className="services-card">
              <div className="services-icon">{service.icon}</div>
              <h3 className="services-card-title">{service.title}</h3>
              <p className="services-card-description">{service.description}</p>
              <a href={service.link} className="services-card-link">
                View details
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
