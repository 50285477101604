import Header from "../../Header/Header";  // Import the Header component
import Footer from "../../Footer/Footer";  // Import the Footer component
import './MobileDevelopment.css';
import WebServices from "./webServices/WebServices";  // Import the WebServices screen
import HowWeWork from "./howWeWork/HowWeWork";  // Import the HowWeWork screen
import Expertise from "./expertise/Expertise";  // Import the Expertise screen
import '../../../Component/GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

function MobileDevelopment() {
  return (
    <div className="home-container">
      <Header />

      <div className="hero-sectionM">
        <div className="hero-textA">
          <h1 className="hero-aboutA">Empowering Your Vision with Outstanding Mobile Development!</h1>
          <p className="about-disA">Harness the potential of our tailored mobile development solutions as we create a digital pathway for your product.</p>
        </div>
      </div>

      {/* Use the WebServices screen */}
      <WebServices />
      {/* Use the WebServices screen */}
      <HowWeWork />
      {/* Use the WebServices screen */}
      {/* <Expertise /> */}
      {/* Use the Footer component */}
      <Footer />
    </div>
  );
}

export default MobileDevelopment;
