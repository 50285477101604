import React, { useState } from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "./ContactForm.css";
import '../../Component/GlobalFonts/GlobalFonts.css';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
        reason: "Looking to build a product",
    });

    const [errors, setErrors] = useState({});
    const [charCount, setCharCount] = useState(0);
    const [popupVisible, setPopupVisible] = useState(false); // New state for popup visibility

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        if (name === "message") {
            setCharCount(value.length);
        }
    };

    const handlePhoneChange = (phone) => {
        setFormData({ ...formData, phone });
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) {
            newErrors.name = "Name is required";
        }

        if (!formData.email) {
            newErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Email address is invalid";
        }

        if (!formData.phone) {
            newErrors.phone = "Phone number is required";
        } else if (!/^\d{10,15}$/.test(formData.phone)) {
            newErrors.phone = "Phone number is invalid";
        }

        if (!formData.message) {
            newErrors.message = "Message is required";
        }

        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            // API request setup
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            const urlencoded = new URLSearchParams();
            urlencoded.append("name", formData.name);
            urlencoded.append("mobile", formData.phone);
            urlencoded.append("email", formData.email);
            urlencoded.append("reason", formData.reason);
            urlencoded.append("message", formData.message);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
                mode: "no-cors",
            };

            // Fetch API call
            fetch("https://support.fingerss.com", requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    console.log("Form submitted successfully:", result);
                    setPopupVisible(true); // Show popup on success
                    setFormData({
                        name: "",
                        email: "",
                        phone: "",
                        message: "",
                        reason: "Looking to build a product",
                    });
                    setCharCount(0);
                    setErrors({});

                    // Hide popup after 3 seconds
                    setTimeout(() => setPopupVisible(false), 3000);
                })
                .catch((error) => console.error("Error submitting form:", error));
        } else {
            setErrors(validationErrors);
        }
    };

    return (
        <form className="contact-form" onSubmit={handleSubmit}>
            <h2 className="contact-title">Fill out the Form</h2>
            <p className="contact-details-title">Help us with the following details</p>

            <div className="form-grid">
                {/* Name Field */}
                <div className="form-group">
                    <label className="contact-details-title">What should we call you? *</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={errors.name ? "error" : ""}
                        placeholder="Enter your name"
                    />
                    {errors.name && <span className="error-text">{errors.name}</span>}
                </div>

                {/* Phone Field */}
                <div className="form-group">
                    <label className="contact-details-titleA">What is your mobile number? *</label>
                    <PhoneInput
                        country={'in'}
                        value={formData.phone}
                        onChange={handlePhoneChange}
                        inputClass={`phone-input ${errors.phone ? "error" : ""}`}
                        placeholder="Enter your mobile number"
                        inputStyle={{ width: '100%',height:42.5 }}
                        enableAreaCodes={true}           // Enables area codes
                        enableAreaCodeStretch={true}      // Stretches area code in the input
                        countryCodeEditable={false}       // Prevents editing of the country code
                    />
                    {errors.phone && <span className="error-text">{errors.phone}</span>}
                </div>

                {/* Email Field */}
                <div className="form-group">
                    <label className="contact-details-title">What is your email address? *</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={errors.email ? "error" : ""}
                        placeholder="Enter your email"
                    />
                    {errors.email && <span className="error-text">{errors.email}</span>}
                </div>

                {/* Reason Field */}
                <div className="form-group">
                    <label className="contact-details-title">You're reaching out to us for?</label>
                    <select
                        name="reason"
                        value={formData.reason}
                        onChange={handleChange}
                        style={{height:44}}
                    >
                        <option className="contact-details-title" value="Looking to build a product">Looking to build a product</option>
                        <option className="contact-details-title" value="Looking for services">Looking for services</option>
                        <option className="contact-details-title" value="Just inquiring">Just inquiring</option>
                    </select>
                </div>
            </div>

            {/* Message Field */}
            <div className="form-group">
                <label className="contact-details-title">Message *</label>
                <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className={errors.message ? "error" : ""}
                    placeholder="Enter your message..."
                    maxLength="1000"
                />
                {errors.message && <span className="error-text">{errors.message}</span>}
                <div className="char-count">{charCount}/1000</div>
            </div>

            {/* Submit Button */}
            <div className="form-group">
                <button type="submit" className="submit-btn">Submit</button>
            </div>

            {/* Popup Message */}
            {popupVisible && (
                <div className="popup">
                    <p className="submitted-popup">Form submitted successfully!</p>
                </div>
            )}
        </form>
    );
};

export default ContactForm;
