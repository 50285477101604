import React from 'react';
import './HowWeWork.css';
import '../../../GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const HowWeWork = () => {
  return (
    <section className="how-we-work-section">
      <div className="container">
        {/* Header */}
        <h1 className="how-we-work-title">Challenges We Solve</h1>
        <p className="how-we-work-description">
        Unlock the power of data and AI to overcome complex challenges and achieve your business goals.
        </p>

        {/* Cards Section */}
        <div className="how-we-work-grid">
          {/* Discovery and Planning */}
          <div className="how-we-work-card">
            <div className="icon">🔍</div>
            <h2 className="card-title">Discovery and Planning</h2>
            <p className="card-description">
              We initiate the process by effective collaboration to gain a deep understanding of your business objectives, target audience, and project requirements and then focus on building a strategic roadmap.
            </p>
          </div>

          {/* Design and Development */}
          <div className="how-we-work-card">
            <div className="icon">✏️</div>
            <h2 className="card-title">Design and Development</h2>
            <p className="card-description">
              Based on the planning and discovery, our skilled designers work on wireframes and prototypes to visualize the final website, incorporating the brand identity and user experience principles.
            </p>
          </div>

          {/* Testing and Optimization */}
          <div className="how-we-work-card">
            <div className="icon">✔️</div>
            <h2 className="card-title">Testing and Optimization</h2>
            <p className="card-description">
              Our skilled team conducts rigorous testing to identify and resolve any potential issues. Through meticulous optimization, we aim to deliver optimal performance, security, and reliability.
            </p>
          </div>

          {/* Deployment and Support */}
          <div className="how-we-work-card">
            <div className="icon">📦</div>
            <h2 className="card-title">Deployment and Support</h2>
            <p className="card-description">
              We provide end-to-end support during the deployment phase, ensuring a smooth and seamless launch. Our team remains dedicated to ongoing support and maintenance.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowWeWork;
