import React from 'react';
import './WebServices.css';
import '../../../GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const WebServices = () => {
  return (
    <section className="services-section">
      <div className="container">
        {/* Header */}
        <h1 className="services-title">Our Services</h1>
        <p className="services-description">
        From functional testing to performance evaluation, we provide various services tailored to your business's diverse quality assurance needs. Here are our powerful solutions that can pave the way for a robust future for your business:
        </p>

        {/* Service Cards */}
        <div className="services-grid">
          {/* Front-end Technology */}
          <div className="service-card">
            <div className="service-icon">🖥️</div>
            <h2 className="service-title">Front-end Technology</h2>
            <p className="service-description">
              We offer advanced front-end development services to transform your product into a user-centric masterpiece. Benefit from enhanced usability, intuitive navigation, and visually stunning interfaces.
            </p>
          </div>

          {/* Back-end Technology */}
          <div className="service-card">
            <div className="service-icon">💻</div>
            <h2 className="service-title">Back-end Technology</h2>
            <p className="service-description">
              Our expert team uses state-of-the-art technologies to build a powerful back-end, enhancing performance, security, and data management with reliable integration.
            </p>
          </div>

          {/* Cloud Support */}
          <div className="service-card">
            <div className="service-icon">☁️</div>
            <h2 className="service-title">Cloud Support</h2>
            <p className="service-description">
              Our powerful cloud solutions offer scalability and flexibility, allowing seamless adaptation to changing demands. Optimize your product's infrastructure with our cloud services.
            </p>
          </div>

          {/* Responsive Web Development */}
          <div className="service-card">
            <div className="service-icon">📱</div>
            <h2 className="service-title">Responsive Web Development</h2>
            <p className="service-description">
              We build responsive websites that seamlessly adapt to different screen sizes, ensuring an optimal user experience across all devices.
            </p>
          </div>

          {/* Cross-Browser Compatibility */}
          <div className="service-card">
            <div className="service-icon">🌐</div>
            <h2 className="service-title">Cross-Browser Compatibility</h2>
            <p className="service-description">
              Our developers ensure that your website performs flawlessly across various browsers, ensuring a reliable user experience.
            </p>
          </div>

          {/* Performance Optimization */}
          <div className="service-card">
            <div className="service-icon">📊</div>
            <h2 className="service-title">Performance Optimization</h2>
            <p className="service-description">
              We optimize the performance of your website with the latest techniques to ensure fast loading times and smooth navigation.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WebServices;
