import React from 'react';
import './OurVision.css'; // Import the CSS file for styling
import '../../Component/GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const OurVision = () => {
  return (
    <main className="vision-section">
      <div className="vision-content">
        <h4 className='our-vision'>Our Vision</h4>
        <p className='vision-dis'>
        At Fingerss, our vision is to deliver impactful web and mobile applications that lead the market while fostering the growth and well-being of our employees. We are committed to success, both for our clients and our team.
        </p>
      </div>
    </main>
  );
};

export default OurVision;
