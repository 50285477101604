import React from 'react';
import Marquee from 'react-fast-marquee';
import './Technologies.css';
import '../../Component/GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

// Programming Languages 
import netLogo from '../../assets/images/TechnologiesImage/ProgrammingLanguages/net.svg';
import python from '../../assets/images/TechnologiesImage/ProgrammingLanguages/python.svg';
import Dart from '../../assets/images/TechnologiesImage/ProgrammingLanguages/dart.svg';
import Swift from '../../assets/images/TechnologiesImage/ProgrammingLanguages/swift.svg';
import java from '../../assets/images/TechnologiesImage/ProgrammingLanguages/java.svg';
import kotlin from '../../assets/images/TechnologiesImage/ProgrammingLanguages/kotlin.svg';
import obj from '../../assets/images/TechnologiesImage/ProgrammingLanguages/objective-c.svg';
import php from '../../assets/images/TechnologiesImage/ProgrammingLanguages/php.svg';
import matlab from '../../assets/images/TechnologiesImage/ProgrammingLanguages/matlab.svg';

// Frameworks Section
import angular from '../../assets/images/TechnologiesImage/Frameworks/angularjs.svg';
import blazor from '../../assets/images/TechnologiesImage/Frameworks/blazor.svg';
import cocos from '../../assets/images/TechnologiesImage/Frameworks/cocos-creator.svg';
import django from '../../assets/images/TechnologiesImage/Frameworks/django.svg';
import flutter from '../../assets/images/TechnologiesImage/Frameworks/flutter.svg';
import images from '../../assets/images/TechnologiesImage/Frameworks/maui.svg';
import laravel from '../../assets/images/TechnologiesImage/Frameworks/laravel.svg';
import maui from '../../assets/images/TechnologiesImage/Frameworks/maui.svg';
import next from '../../assets/images/TechnologiesImage/Frameworks/nextjs.svg';
import node from '../../assets/images/TechnologiesImage/Frameworks/node.svg';
import reactIcon from '../../assets/images/TechnologiesImage/Frameworks/reactJS.svg';
import rn from '../../assets/images/TechnologiesImage/Frameworks/react-native.svg';
import spring from '../../assets/images/TechnologiesImage/Frameworks/spring-boot.svg';
import swiftUI from '../../assets/images/TechnologiesImage/Frameworks/swift-ui.svg';
import vue from '../../assets/images/TechnologiesImage/Frameworks/vuejs.svg';

// Databases Section
import mariadb from '../../assets/images/TechnologiesImage/Databases/mariadb.svg';
import mongod from '../../assets/images/TechnologiesImage/Databases/mongodb.svg';
import mssql_sql from '../../assets/images/TechnologiesImage/Databases/microsoftsql-server.svg';
import mysql from '../../assets/images/TechnologiesImage/Databases/mysql.svg';
import postgresql from '../../assets/images/TechnologiesImage/Databases/postgresql.svg';
import sqlite from '../../assets/images/TechnologiesImage/Databases/sqlite.svg';

// DevOps Section 
import Azure from '../../assets/images/TechnologiesImage/DevOps/azure-devops.svg';
import Docker from '../../assets/images/TechnologiesImage/DevOps/docker.svg';
import aws_tools from '../../assets/images/TechnologiesImage/DevOps/aws-developer-tools.svg';
import devtools from '../../assets/images/TechnologiesImage/DevOps/google-developer-tools.svg';
import elastic from '../../assets/images/TechnologiesImage/DevOps/elastic-search.svg';
import jenkins from '../../assets/images/TechnologiesImage/DevOps/jenkins.svg';
import kubernetes from '../../assets/images/TechnologiesImage/DevOps/kubernetes.svg';

// Cloud Platforms Section
import DigitalOcean from '../../assets/images/TechnologiesImage/CloudPlatforms/digital-ocean.svg';
import Microsoft_Azure from '../../assets/images/TechnologiesImage/CloudPlatforms/microsoft-azure.svg';
import akamai from '../../assets/images/TechnologiesImage/CloudPlatforms/akamai.svg';
import aws from '../../assets/images/TechnologiesImage/CloudPlatforms/aws.svg';
import google_cloud from '../../assets/images/TechnologiesImage/CloudPlatforms/google-cloud.svg';

// Version Control Section
import bitbucket from '../../assets/images/TechnologiesImage/VersionControl/bitbucket.svg';
import git from '../../assets/images/TechnologiesImage/VersionControl/git.svg';

//IDEs Section
import android_studio from '../../assets/images/TechnologiesImage/IDEs/android-studio.svg';
import atomsign from '../../assets/images/TechnologiesImage/IDEs/atom.svg';
import ecode from '../../assets/images/TechnologiesImage/IDEs/eclipse.svg';
import vs_code_icon from '../../assets/images/TechnologiesImage/IDEs/visual-studio-code.svg';
import xcode from '../../assets/images/TechnologiesImage/IDEs/xcode.svg';

//ERP & CRM Solutions Section
import Dynamics_365 from '../../assets/images/TechnologiesImage/ERP_CRM/microsoft.svg';
import ERPnext from '../../assets/images/TechnologiesImage/ERP_CRM/erpnext.svg';
import oddo from '../../assets/images/TechnologiesImage/ERP_CRM/odoo.svg';

//PMS tools Section
import Jira_Logo from '../../assets/images/TechnologiesImage/PMStools/jira.svg';
import Trello_logo from '../../assets/images/TechnologiesImage/PMStools/trello.svg';
import asana from '../../assets/images/TechnologiesImage/PMStools/asana.svg';

//Testing Section
import junit from '../../assets/images/TechnologiesImage/Testing/junit.svg';
import selenium from '../../assets/images/TechnologiesImage/Testing/selenium.svg';
import jmeter from '../../assets/images/TechnologiesImage/Testing/jmeter.svg';

//Communication Section
import Slack from '../../assets/images/TechnologiesImage/Communication/slack.svg';
import basecamp from '../../assets/images/TechnologiesImage/Communication/basecamp.svg';
import msteams from '../../assets/images/TechnologiesImage/Communication/microsoft-teams.svg';
import skype from '../../assets/images/TechnologiesImage/Communication/skype.svg';


const Technologies = () => {
  return (
    <div className="tech-stack-container">
      <h1 className='title-name'>Our Technologies</h1>
      <p className='title-name-dis'>We leverage a wide range of diverse technologies, incorporating the latest and most renowned tech stacks and platforms.</p>

      {/* Programming Languages Section */}
      <div className="tech-section">
        <h3 className='program-title'>Programming Languages</h3>
        <div className="tech-marquee-container">
          <Marquee
            speed={50}
            pauseOnHover={true}
            gradient={false}
          >
            <div className="tech-item">
              <img src={netLogo} alt=".Net" />
            </div>
            <div className="tech-item">
              <img src={python} alt="Python" />
            </div>
            <div className="tech-item">
              <img src={Dart} alt="Swift" />
            </div>
            <div className="tech-item">
              <img src={Swift} alt="Java" />
            </div>
            <div className="tech-item">
              <img src={java} alt="java" />
            </div>
            <div className="tech-item">
              <img src={kotlin} alt="Kotlin" />
            </div>
            <div className="tech-item">
              <img src={obj} alt="obj" />
            </div>
            <div className="tech-item">
              <img src={php} alt="php" />
            </div>
            <div className="tech-item">
              <img src={matlab} alt="matlab" />
            </div>
          </Marquee>
        </div>
      </div>

      {/* Frameworks Section */}
      <div className="tech-section">
        <h3 className='program-title'>Frameworks</h3>
        <div className="tech-marquee-container">
          <Marquee
            speed={50}
            pauseOnHover={true}
            gradient={false}
          >
            <div className="tech-item">
              <img src={angular} alt=".Net" />
            </div>
            <div className="tech-item">
              <img src={blazor} alt="Python" />
            </div>
            <div className="tech-item">
              <img src={cocos} alt="Swift" />
            </div>
            <div className="tech-item">
              <img src={django} alt="Java" />
            </div>
            <div className="tech-item">
              <img src={flutter} alt="Kotlin" />
            </div>
            <div className="tech-item">
              <img src={images} alt=".Net" />
            </div>
            <div className="tech-item">
              <img src={laravel} alt="Python" />
            </div>
            <div className="tech-item">
              <img src={maui} alt="Swift" />
            </div>
            <div className="tech-item">
              <img src={next} alt="Java" />
            </div>
            <div className="tech-item">
              <img src={node} alt="Kotlin" />
            </div>
            <div className="tech-item">
              <img src={reactIcon} alt=".Net" />
            </div>
            <div className="tech-item">
              <img src={rn} alt="Python" />
            </div>
            <div className="tech-item">
              <img src={spring} alt="Swift" />
            </div>
            <div className="tech-item">
              <img src={swiftUI} alt="Java" />
            </div>
            <div className="tech-item">
              <img src={vue} alt="Kotlin" />
            </div>
          </Marquee>
        </div>
      </div>

      {/* Databases Section */}
      <div className="tech-section">
        <h3 className='program-title'>Databases</h3>
        <div className="tech-marquee-container">
          <Marquee
            speed={50}
            pauseOnHover={true}
            gradient={false}
          >
            <div className="tech-item">
              <img src={mariadb} alt=".Net" />
            </div>
            <div className="tech-item">
              <img src={mongod} alt="Python" />
            </div>
            <div className="tech-item">
              <img src={mssql_sql} alt="Swift" />
            </div>
            <div className="tech-item">
              <img src={mysql} alt="Java" />
            </div>
            <div className="tech-item">
              <img src={postgresql} alt="Kotlin" />
            </div>
            <div className="tech-item">
              <img src={sqlite} alt="Kotlin" />
            </div>
          </Marquee>
        </div>
      </div>

      {/* DevOps Section */}
      <div className="tech-section">
        <h3 className='program-title'>DevOps</h3>
        <div className="tech-marquee-container">
          <Marquee
            speed={50}
            pauseOnHover={true}
            gradient={false}
          >
            <div className="tech-item">
              <img src={Azure} alt=".Net" />
            </div>
            <div className="tech-item">
              <img src={Docker} alt="Swift" />
            </div>
            <div className="tech-item">
              <img src={aws_tools} alt="Java" />
            </div>
            <div className="tech-item">
              <img src={devtools} alt="Kotlin" />
            </div>
            <div className="tech-item">
              <img src={elastic} alt="Kotlin" />
            </div>
            <div className="tech-item">
              <img src={jenkins} alt="Kotlin" />
            </div>
            <div className="tech-item">
              <img src={kubernetes} alt="Kotlin" />
            </div>
          </Marquee>
        </div>
      </div>

      {/* Cloud Platforms Section */}
      <div className="tech-section">
        <h3 className='program-title'>Cloud Platforms</h3>
        <div className="tech-marquee-container">
          <Marquee
            speed={50}
            pauseOnHover={true}
            gradient={false}
          >
            <div className="tech-item">
              <img src={DigitalOcean} alt=".Net" />
            </div>
            <div className="tech-item">
              <img src={Microsoft_Azure} alt="Python" />
            </div>
            <div className="tech-item">
              <img src={akamai} alt="Swift" />
            </div>
            <div className="tech-item">
              <img src={aws} alt="Java" />
            </div>
            <div className="tech-item">
              <img src={google_cloud} alt="Kotlin" />
            </div>
          </Marquee>
        </div>
      </div>

      {/* Version Control Section */}
      <div className="tech-section">
        <h3 className='program-title'>Version Control</h3>
        <div className="tech-marquee-container">
          <Marquee
            speed={50}
            pauseOnHover={true}
            gradient={false}
          >
            <div className="tech-item">
              <img src={bitbucket} alt=".Net" />
            </div>
            <div className="tech-item">
              <img src={git} alt="Python" />
            </div>
          </Marquee>
        </div>
      </div>

      {/* IDEs Section */}
      <div className="tech-section">
        <h3 className='program-title'>IDEs</h3>
        <div className="tech-marquee-container">
          <Marquee
            speed={50}
            pauseOnHover={true}
            gradient={false}
          >
            <div className="tech-item">
              <img src={android_studio} alt=".Net" />
            </div>
            <div className="tech-item">
              <img src={atomsign} alt="Python" />
            </div>
            <div className="tech-item">
              <img src={ecode} alt="Swift" />
            </div>
            <div className="tech-item">
              <img src={vs_code_icon} alt="Java" />
            </div>
            <div className="tech-item">
              <img src={xcode} alt="Java" />
            </div>
          </Marquee>
        </div>
      </div>

      {/* ERP & CRM Solutions Section */}
      <div className="tech-section">
        <h3 className='program-title'>ERP & CRM Solutions</h3>
        <div className="tech-marquee-container">
          <Marquee
            speed={50}
            pauseOnHover={true}
            gradient={false}
          >
            <div className="tech-item">
              <img src={Dynamics_365} alt=".Net" />
            </div>
            <div className="tech-item">
              <img src={ERPnext} alt="Python" />
            </div>
            <div className="tech-item">
              <img src={oddo} alt="Swift" />
            </div>
          </Marquee>
        </div>
      </div>

      {/* PMS tools Section */}
      <div className="tech-section">
        <h3 className='program-title'>PMS tools</h3>
        <div className="tech-marquee-container">
          <Marquee
            speed={50}
            pauseOnHover={true}
            gradient={false}
          >
            <div className="tech-item">
              <img src={Jira_Logo} alt=".Net" />
            </div>
            <div className="tech-item">
              <img src={Trello_logo} alt="Python" />
            </div>
            <div className="tech-item">
              <img src={asana} alt="Swift" />
            </div>
          </Marquee>
        </div>
      </div>

      {/* Testing Section */}
      <div className="tech-section">
        <h3 className='program-title'>Testing</h3>
        <div className="tech-marquee-container">
          <Marquee
            speed={50}
            pauseOnHover={true}
            gradient={false}
          >
            <div className="tech-item">
              <img src={junit} alt=".Net" />
            </div>
            <div className="tech-item">
              <img src={selenium} alt="Python" />
            </div>
            <div className="tech-item">
              <img src={jmeter} alt="Python" />
            </div>
          </Marquee>
        </div>
      </div>

      {/* Communication Section */}
      <div className="tech-section" style={{ marginBottom: 20 }}>
        <h3 className='program-title'>Communication</h3>
        <div className="tech-marquee-container">
          <Marquee
            speed={50}
            pauseOnHover={true}
            gradient={false}
          >
            <div className="tech-item">
              <img src={Slack} alt=".Net" />
            </div>
            <div className="tech-item">
              <img src={basecamp} alt="Python" />
            </div>
            <div className="tech-item">
              <img src={msteams} alt="Swift" />
            </div>
            <div className="tech-item">
              <img src={skype} alt="Java" />
            </div>
          </Marquee>
        </div>
      </div>

    </div>
  );
};

export default Technologies;
