import Header from "../../Component/Header/Header";  // Import the Header component
import Footer from "../../Component/Footer/Footer";  // Import the Footer component
import CareersMedia from "../../Component/CareersMedia/CareersMedia";  // Import the Leadership component
import LifeAtCoditas from "../../Component/CareersMedia/LifeAtCoditas";  // Import the LifeAtCoditas component
// import Testimonial from "../../Component/CareersMedia/Testimonial";  // Import the Testimonial component
// import OpenPositions from "../../Component/CareersMedia/OpenPositions";  // Import the OpenPositions component

import './Careers.css';
import '../../Component/GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css
import engineers_icon from '../../assets/images/engineers_icon.svg';
import applications_icons from '../../assets/images/applications_icons.svg';
import products_icon from '../../assets/images/products_icon.svg';
import growth_icon from '../../assets/images/growth_icon.svg';

function Careers() {

  return (
    <div className="home-container">
      {/* Use the Header component */}
      <Header />
      <div className="hero-section-dev">
        <div className="hero-text-dev-data">
          <h1 className="Create-head">Build Meaningful Software with Us </h1>
          <p className="Create-head-disc1">Join our team and grow your career in an environment where your ideas and potential are truly appreciated."</p>
          {/* <button className="cta-button1">Join Us</button> */}
        </div>
      </div>

      {/* Use the Who we are screen */}

      <div className="about-container">
        <h1 className="Join-text">Why Join Fingerss</h1>
        <p className="Join-text-dis">
          At Fingerss, we bring together visionaries and achievers, blending creativity with technical expertise. Our employee-focused HR policies, competitive salaries, dynamic work culture, and opportunities to collaborate with talented peers make this the ideal environment to grow your career and make a lasting impact.</p>

        <h3 className="perks-text">Perks of Working at Fingerss</h3>
        <div className="features-grid">
          <div className="feature-card">
            <img src={engineers_icon} alt="Open Culture" className="icon" />
            <h3 className="feature-card-text" > Enjoy a welcoming and inclusive open culture where diversity in beliefs, cultures, and people is celebrated.</h3>
          </div>
          <div className="feature-card">
            <img src={applications_icons} alt="Collaborate with Industry Leaders" className="icon" />
            <h3 className="feature-card-text">Work alongside industry experts and gain valuable insights to enhance your professional growth.</h3>
          </div>
          <div className="feature-card">
            <img src={products_icon} alt="Team Bonding" className="icon" />
            <h3 className="feature-card-text"> Participate in fun and engaging indoor and outdoor team bonding activities and events.</h3>
          </div>
        </div>
      </div>

      {/* Use the CareersMedia component */}
      {/* <CareersMedia /> */}

      {/* Use the CareersMedia component */}
      <LifeAtCoditas />

      {/* Use the Testimonial component */}
      {/* <Testimonial /> */}

      {/* Use the OpenPositions component */}
      {/* <OpenPositions /> */}

      {/* Use the Footer component */}
      <Footer />
    </div>
  );
}

export default Careers;
