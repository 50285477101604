import Header from "../../Header/Header";  // Import the Header component
import Footer from "../../Footer/Footer";  // Import the Footer component
import './UXDesing.css';
import WebServices from "./webServices/WebServices";  // Import the WebServices screen
import HowWeWork from "./howWeWork/HowWeWork";  // Import the HowWeWork screen
// import Expertise from "./expertise/Expertise";  // Import the Expertise screen
import WhyChoose from "./WhyChoose/WhyChoose";  // Import the Expertise screen
import '../../../Component/GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

function UXDesing() {
  return (
    <div className="home-container">
      <Header />

      <div className="hero-sectionU">
        <div className="hero-textA">
          <h1 className="hero-aboutA">Elevating User Experiences with Custom UX Design Solutions!</h1>
          <p className="about-disA">Transforming digital interactions with intuitive and user-centered UX design.</p>
        </div>
      </div>

      {/* Use the WebServices screen */}
      <WebServices />
      {/* Use the HowWeWork screen */}
      <HowWeWork />
      {/* Use the WhyChoose screen */}
      <WhyChoose />
      {/* Use the WebServices screen */}
      {/* <Expertise /> */}
      {/* Use the Footer component */}
      <Footer />
    </div>
  );
}

export default UXDesing;
