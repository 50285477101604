import React from 'react';
import './WebServices.css';
import '../../../GlobalFonts/GlobalFonts.css';  // Import the global font styles from global.css

const WebServices = () => {
  return (
    <section className="services-section">
      <div className="container">
        {/* Header */}
        <h1 className="services-title">Our Services</h1>
        <p className="services-descriptionG">We focus on creating custom chatbots tailored for diverse industries, utilizing advanced generative AI technology. Our chatbots deliver personalized, seamless interactions, enhancing customer engagement and improving business efficiency.</p>

        {/* Service Cards */}
        <div className="services-grid">
          {/* Front-end Technology */}
          <div className="service-card">
            <div className="service-icon">🖥️</div>
            <h2 className="service-title">Custom Chatbot Development</h2>
            <p className="service-descriptionG">We develop smart chatbot solutions customized to your industry and business requirements. Using the data you provide, we train the chatbots to deliver a smooth, intuitive experience. Our expert team evaluates your needs and harnesses generative AI to create chatbots that simulate human-like conversations, guaranteeing an outstanding user experience.</p>
          </div>

          {/* Back-end Technology */}
          <div className="service-card">
            <div className="service-icon">💻</div>
            <h2 className="service-title">Natural Language Processing</h2>
            <p className="service-descriptionG">Our chatbots are equipped with advanced natural language processing (NLP) capabilities, allowing them to accurately comprehend and interpret user queries. Leveraging generative AI, they deliver intelligent, relevant, and personalized responses, ensuring effective customer support and engagement.</p>
          </div>

          {/* Cloud Support */}
          <div className="service-card">
            <div className="service-icon">☁️</div>
            <h2 className="service-title">Integration and Deployment</h2>
            <p className="service-descriptionG">We effortlessly integrate our custom chatbots into your existing systems and platforms. Whether it's your website, mobile app, or social media channels, our team ensures a seamless deployment process, enabling your chatbot to interact with customers across various touchpoints.</p>
          </div>

          {/* Responsive Web Development */}
          <div className="service-card">
            <div className="service-icon">📱</div>
            <h2 className="service-title">Continuous Learning and Improvement</h2>
            <p className="service-descriptionG">Our chatbots continuously learn and evolve through machine learning algorithms that analyze user interactions and feedback. This enables the chatbot to refine its responses and adapt to changing customer needs, ensuring it remains current and consistently provides exceptional customer experiences.</p>
          </div>

        </div>
      </div>
    </section>
  );
};

export default WebServices;
